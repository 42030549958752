import './wovenstar.css';

const WovenStar = () => {
  return (
    <>
      <div className='spinnerwrapper'>
        <div className='itemwrapper'>
          <div className='item'></div>
        </div>
        <div className='itemwrapper'>
          <div className='item'></div>
        </div>
        <div className='itemwrapper'>
          <div className='item'></div>
        </div>
        <div className='itemwrapper'>
          <div className='item'></div>
        </div>
        <div className='itemwrapper'>
          <div className='item'></div>
        </div>
        <div className='itemwrapper'>
          <div className='item'></div>
        </div>
        <div className='itemwrapper'>
          <div className='item'></div>
        </div>
        <div className='itemwrapper'>
          <div className='item'></div>
        </div>
        <div className='itemwrapper'>
          <div className='item'></div>
        </div>
        <div className='itemwrapper'>
          <div className='item'></div>
        </div>
        <div className='itemwrapper'>
          <div className='item'></div>
        </div>
        <div className='itemwrapper'>
          <div className='item'></div>
        </div>
      </div>

    </>
  )
}

export default WovenStar